import React, { useCallback, useEffect } from 'react';
import AppBar from 'components/AppBar';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import {
  changeLocaleSaga,
  fetchAuthenticatedUser,
  logout,
  updateAuthenticatedUser,
} from './actions';
import Routes from './Routes';
import saga from './saga';
import { makeSelectToken, makeSelectUser } from './selectors';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner.presentation';
import Footer from '../../components/Footer';
import styles from './App.module.scss';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../routes';
import SnackbarComponent from '../../components/Snackbar/SnackbarComponent';
import Loader from '../../components/LoaderComponent';

const key = 'app';

function App() {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const history = useHistory();
  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthenticatedUser());
    } else {
      history.push(LOGIN);
    }
  }, [token, dispatch, history]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleLanguageChange = useCallback(
    (val) => {
      dispatch(changeLocaleSaga(val));
      dispatch(
        updateAuthenticatedUser({ user: { ...user, selected_language: val } })
      );
    },
    [dispatch, user]
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumanity Client</title>
      </Helmet>
      {token && !user ? (
        <Loader />
      ) : (
        <>
          {user && (
            <AppBar
              onLanguageChange={handleLanguageChange}
              user={user}
              onLogout={handleLogout}
            />
          )}
          <div className={styles.container}>
            <Routes />
            <Loader />
          </div>
          {user && <Footer />}
        </>
      )}
      <SnackbarComponent />
    </HelmetProvider>
  );
}

export default App;
