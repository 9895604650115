import produce from 'immer';
import { SET_LOADER } from './constants';

export const initialState = {
  loader: false,
};

/* eslint-disable default-case */
const loaderReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_LOADER:
        draft.loader = payload;
        break;
    }
  });

export default loaderReducer;
