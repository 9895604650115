import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { loaderSelector } from './selectors';

const Loader = () => {
  const isLoading = useSelector(loaderSelector());

  return (
    <div>
      {isLoading && (
        <div className={styles.container}>
          <CircularProgress thickness={5} style={{ color: '#FFF200' }} />
        </div>
      )}
    </div>
  );
};

export default Loader;
